import React, { useState } from "react"
import { toast } from "react-toastify"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Slider2 from "../components/slider2"

import { sendContactMessage } from "../store/actions/otherActions"
import { connect } from "react-redux"

const pageName = "CONTACTEZ-NOUS"
const ContactUs = ({ sendContactMessage, isLoading }) => {
  const [name, setName] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [emailAddress, setEmailAddress] = useState("")
  const [subject, setSubject] = useState("")
  const [message, setMessage] = useState("")
  const initState = () => {
    setName("")
    setPhoneNumber("")
    setEmailAddress("")
    setSubject("")
    setMessage("")
  }
  const handleSendContactMessage = e => {
    e.preventDefault()
    let contact = {
      name: name,
      phone_numbers: phoneNumber,
      email_address: emailAddress,
      subject: subject,
      message: message,
    }
    sendContactMessage(contact)
      .then(() => {
        initState()
        toast.success(
          "Message envoyé 🚀. Nous vous contacterons très bientôt.",
          {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        )
      })
      .catch(() => {})
  }
  return (
    <Layout>
      <SEO title={pageName} />
      <Slider2 title={pageName} />
      <div className="flex flex-col justify-center py-6">
        <div className="container mx-auto">
          <div className="flex flex-col">
            <span className="text-center text-sm pt-2 font-semibold text-blue-600">
              Contactez-nous
            </span>
            <h1 className="text-center text-2xl">Laissez-nous un message</h1>
            <span className="text-gray-600 text-xs text-center px-4 pb-8">
              Nous souhaitons vraiment savoir ce que vous pensez de nos services
              <br />
              afin de les améliorer et de vous retourner vos vêtements toujours
              <br />
              aussi <strong>propres</strong> et <strong>éclatants</strong>.
            </span>
          </div>
          <div className="flex flex-wrap pt-2 pb-12 mx-auto">
            <div className="w-full md:w-1/3 px-3">
              <div className="rounded overflow-hidden shadow-lg text-center py-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  className="text-blue-600 h-20 w-20 fill-current mx-auto pb-4"
                >
                  <path d="M14.608 12.172c0 .84.239 1.175.864 1.175 1.393 0 2.28-1.775 2.28-4.727 0-4.512-3.288-6.672-7.393-6.672-4.223 0-8.064 2.832-8.064 8.184 0 5.112 3.36 7.896 8.52 7.896 1.752 0 2.928-.192 4.727-.792l.386 1.607c-1.776.577-3.674.744-5.137.744-6.768 0-10.393-3.72-10.393-9.456 0-5.784 4.201-9.72 9.985-9.72 6.024 0 9.215 3.6 9.215 8.016 0 3.744-1.175 6.6-4.871 6.6-1.681 0-2.784-.672-2.928-2.161-.432 1.656-1.584 2.161-3.145 2.161-2.088 0-3.84-1.609-3.84-4.848 0-3.264 1.537-5.28 4.297-5.28 1.464 0 2.376.576 2.782 1.488l.697-1.272h2.016v7.057h.002zm-2.951-3.168c0-1.319-.985-1.872-1.801-1.872-.888 0-1.871.719-1.871 2.832 0 1.68.744 2.616 1.871 2.616.792 0 1.801-.504 1.801-1.896v-1.68z" />
                </svg>
                <span className="text-2xl font-bold">EMAIL</span>
                <br />
                <span className="text-xs">info@monepressing.ci</span>
                <br />
                <span className="text-xs">monepressing@gmail.com</span>
                <br />
              </div>
            </div>
            <div className="w-full md:w-1/3 px-3 py-3">
              <div className="rounded overflow-hidden shadow-lg text-center py-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  className="text-blue-600 h-20 w-20 fill-current mx-auto pb-4"
                >
                  <path d="M17.004 5h-5.008A1.996 1.996 0 0 0 10 6.996v11.008c0 1.102.894 1.996 1.996 1.996h5.008A1.996 1.996 0 0 0 19 18.004V6.996A1.996 1.996 0 0 0 17.004 5zM14.5 19c-.69 0-1.25-.447-1.25-1 0-.553.56-1 1.25-1s1.25.447 1.25 1c0 .553-.56 1-1.25 1zm2.5-3h-5V7h5v9zm-9 0H3V2h12v1h2V2c0-1.101-.9-2-2-2H3C1.9 0 1 .899 1 2v16c0 1.1.9 2 2 2h5.555A3.95 3.95 0 0 1 8 18.003V16z" />
                </svg>
                <span className="text-2xl font-bold">TELEPHONE</span>
                <br />
                <span className="text-xs">+225 07 58 42 05 59</span>
                <br />
                <br />
              </div>
            </div>
            <div className="w-full md:w-1/3 p-3">
              <div className="rounded overflow-hidden shadow-lg text-center py-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  className="text-blue-600 h-20 w-20 fill-current mx-auto pb-4"
                >
                  <path d="M19.367 18.102L18 14h-1.5l.833 4H2.667l.833-4H2L.632 18.102C.285 19.146.9 20 2 20h16c1.1 0 1.715-.854 1.367-1.898zM15 5A5 5 0 1 0 5 5c0 4.775 5 10 5 10s5-5.225 5-10zm-7.7.06A2.699 2.699 0 0 1 10 2.361a2.699 2.699 0 1 1 0 5.399 2.7 2.7 0 0 1-2.7-2.7z" />
                </svg>
                <span className="text-2xl font-bold">ADRESSE</span>
                <br />
                <span className="text-xs">Abidjan, Côte d'ivoire</span>
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
        <div className="container mx-auto">
          <div className="flex flex-wrap pb-4">
            <div className="w-full md:w-1/2 mx-4 md:pr-5 md:mx-0">
              <div className="font-semibold border-b pb-3">
                OÙ SOMMES-NOUS ?
              </div>
              <div className="mx-auto pt-3">
                <iframe
                  width="100%"
                  height="500"
                  frameBorder="0"
                  scrolling="no"
                  marginHeight="0"
                  marginWidth="0"
                  style={{
                    borderRadius: "10px",
                    boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
                  }}
                  src="https://www.openstreetmap.org/export/embed.html?bbox=-4.0285062789917%2C5.286007172124547%2C-3.9855909347534184%2C5.318910579146098&amp;layer=mapnik"
                ></iframe>
              </div>
            </div>
            <div className="w-full md:w-1/2 pl-5 md:pr-0 pr-5">
              <form
                className="w-full max-w-lg"
                onSubmit={handleSendContactMessage}
              >
                <div className="flex flex-wrap">
                  <div className="w-full pb-2">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-name"
                    >
                      Nom
                    </label>
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-name"
                      type="text"
                      onChange={e => setName(e.target.value)}
                      value={name}
                      name="name"
                      required
                    />
                  </div>
                  <div className="w-full md:w-1/2 pr-2 py-2">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-phone-number"
                    >
                      N° de téléphone
                    </label>
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-phone-number"
                      type="text"
                      onChange={e => setPhoneNumber(e.target.value)}
                      value={phoneNumber}
                      name="phoneNumber"
                    />
                  </div>
                  <div className="w-full md:w-1/2 md:pl-2 py-2">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-email-address"
                    >
                      Adresse email
                    </label>
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-email-address"
                      type="email"
                      onChange={e => setEmailAddress(e.target.value)}
                      required
                      name="email"
                      value={emailAddress}
                    />
                  </div>
                  <div className="w-full py-2">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-subject"
                    >
                      Sujet
                    </label>
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-subject"
                      type="text"
                      name="subject"
                      onChange={e => setSubject(e.target.value)}
                      value={subject}
                    />
                  </div>
                  <div className="w-full py-2">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-message"
                    >
                      Message
                    </label>
                    <textarea
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      name="message"
                      id="grid-message"
                      rows="7"
                      onChange={e => setMessage(e.target.value)}
                      value={message}
                    ></textarea>
                  </div>
                  {isLoading && (
                    <div
                      className="w-full bg-blue-100 border border-blue-400 text-blue-700 py-3 rounded relative text-center"
                      role="alert"
                    >
                      <span className="block sm:inline">
                        Chargement en cours ...
                      </span>
                    </div>
                  )}
                  <div className="w-full py-2">
                    {isLoading ? (
                      <span className="text-white bg-green-200 border-green-600 hover:bg-green-800 hover:border-green-800 font-semibold py-3 px-4 rounded text-center shadow-lg text-xs">
                        Envoyer mon message
                      </span>
                    ) : (
                      <button
                        type="submit"
                        className="text-white bg-green-600 border-green-600 hover:bg-green-800 hover:border-green-800 font-semibold py-2 px-4 rounded text-center shadow-lg text-xs"
                      >
                        Envoyer mon message
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

const mapStateToProps = state => ({
  isLoading: state.other.isLoading,
})

const mapDispatchToProps = {
  sendContactMessage,
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs)
